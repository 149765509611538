import dayjs from "dayjs";
import * as wjcCore from "@grapecity/wijmo";
import ferramentas from "@/components/ferramentas";
import botoesAcao from "@/components/botoesAcao";
import moment from "moment";
//import axios from 'axios'
export default {
  components: { ferramentas, botoesAcao },
  data() {
    return {
      keyFlexGrid: 0,
      flexgrid: {},
      abaEscolhida: "",
      keyTabelaWijmo: 0,
      camposEditar: [],
      dados: [],
      abas: [],
      botoes: [
        "EXPORTAR_EXCEL",
        "EXPORTAR_PDF",
        "EDITAR",
        "SALVAR",
        "NOVO",
        "DELETAR",
      ],
      camposEditarProduto: [
        {
          DIVISION: "Dados do médico",
          fields: [
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "Nome",
              hint: "", // opcional
              cols: 12,
              required: true,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce401_nm_medico",
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "CRM",
              hint: "", // opcional
              cols: 6,
              maxLength: 7,
              required: true,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce401_nr_crm",
            },
            {
              model: "",
              vuetify: "autocomplete",
              type: "text",
              nome: "UF CRM",
              hint: "",
              cols: 6,
              required: true,
              chave: "ce401_sg_crmuf",
              items: [
                "AC",
                "AL",
                "AP",
                "AM",
                "BA",
                "CE",
                "ES",
                "GO",
                "MA",
                "MT",
                "MS",
                "MG",
                "PA",
                "PB",
                "PR",
                "PE",
                "PI",
                "RJ",
                "RS",
                "RO",
                "RR",
                "SC",
                "SP",
                "SE",
                "TO",
                "DF",
              ],
            },
          ],
        },
      ],
      opcoesTipo: [
        {
          value: "CE401_NR_CRM",
          text: "Número CRM",
          type: ["number"],
        },
        {
          value: "CE401_NM_MEDICO",
          text: "Nome",
          type: ["text"],
        },
        {
          value: "CE401_NR_MEDICO",
          text: "Nr. médico",
          type: ["number"],
        },
        {
          value: "CE401_SG_CRMUF",
          text: "UF CRM",
          type: ["number"],
        },
      ],
      itensFerramentas: [
        // {
        //   text: "Situação",
        //   value: {
        //     type: "autocomplete",
        //     SP: false,
        //     opcoesTipo: "ce110_cd_situac",
        //     nome: "situacao",
        //     adicionar: false,
        //     filtro: false,
        //     itens: [
        //       { text: "Ativo", value: 1 },
        //       { text: "Bloqueado", value: 2 },
        //     ],
        //     objKey: false,
        //   },
        // },
      ],
      colunasExcel: [
        // {
        //   COMISSÃO: "",
        //   "SALÁRIO FIXO": "",
        //   VENDEDOR: "",
        // },
      ],
      colunas: [
        {
          cabecalho: "Nr.",
          larg: 80,
          valor: "ce401_nr_medico",
          align: "right",
        },
        {
          cabecalho: "UF CRM",
          larg: 120,
          valor: "ce401_sg_crmuf",
          align: "center",
        },
        {
          cabecalho: "CRM",
          larg: 100,
          valor: "ce401_nr_crm",
          align: "right",
          format: "f0",
        },
        {
          cabecalho: "Médico",
          larg: "*",
          valor: "ce401_nm_medico",
          align: "left",
        },
      ],
    };
  },
  methods: {
    async addNovoItem(medico) {
      const usuario = await this.getUser();
      let valEnt = {
        _rNR_CRM: medico.ce401_nr_crm,
        _rUF_CRM: medico.ce401_sg_crmuf,
        _rNM_MEDICO: medico.ce401_nm_medico,
        _rCD_OPESIS: usuario.base.us01_nr_usr,
      };

      try {
        await this.postSP("SP_CE360906001", [valEnt]);
        this.notification().success({mensagem:"Informações salvas com sucesso!"});
      } catch (err) {
        this.notification().error({erro: "Erro ao editar as informações!"});
      }
    },
    initializeGrid(flexgrid) {
      this.flexgrid = flexgrid;
    },
    escolheAba(aba) {
      this.abaEscolhida = aba;
    },
    async mountObj(obj) {
      try {
        this.startLoader();
        const usuario = await this.getUser();
        let medicos = await this.chamaMulti("SP_CE360906002", obj);
        medicos = medicos.database;
        this.getMedicos(medicos);
      } catch {
        this.notification().error({erro: "Erro ao encontrar médicos"});
      } finally {
        this.stopLoader();
      }
    },
    getMedicos(Medicos) {
      try {
        this.dados = Medicos.map((p, i) => {
          return {
            ...p,
            ce401_nr_crm: p.ce401_nr_crm,
            ce401_sg_crmuf: p.ce401_sg_crmuf,
            ce401_nm_medico: p.ce401_nm_medico,
            isSelected: false,
            itemKey: i,
          };
        });
        const backup = this.dados.map((d) => {
          return { ...d };
        });
        const objAba = {
          nome_aba: `${this.$t("TelaLojas.pesquisa")} ${this.abas.length +
            1} - ${dayjs()
            .locale("pt-br")
            .format("DD/MM/YYYY")} - ${this.$t("TelaLojas.quantidade")}: ${
            this.dados.length
          }`,
          pesquisa: Medicos ? this.dados : [],
          remove: false,
          backup: Medicos ? backup : [],
        };
        this.abas.push(objAba);
      } catch (e) {
        this.notification().error({erro: "Erro ao trazer os produtos"});
      } finally {
        this.stopLoader();
      }
    },
    // formataGrid(obj) {
    //   if (obj["ce401_nr_crm"]) {
    //     obj["ce401_nr_crm"] = obj["ce401_nr_crm"].replace(",", "");
    //   }
    // },
    erroBuscaPorFiltro() {
      this.notification().error({erro: "O filtro possui algum erro."});
    },
    async editNovoItem(e) {
      let usuario = await this.getUser();
      let ent = {
        _rNR_MEDICO: e.ce401_nr_medico,
        _rNR_CRM: e.ce401_nr_crm,
        _rUF_CRM: e.ce401_sg_crmuf,
        _rNM_MEDICO: e.ce401_nm_medico,
        _rCD_OPESIS: usuario.base.us01_nr_usr,
      };
      try {
        this.startLoader();
        await this.request({
          metodo: "get",
          sp: "SP_CE360906001",
          params: ent,
        });
        this.notification().success({mensagem:"Informações salvas com sucesso!"});
      } catch {
        this.notification().error({erro: "Erro ao editar as informações"});
      } finally {
        this.stopLoader();
      }
    },

    async salvaEdicao() {
      let usuario = await this.getUser();
      let valEnt = {};
      this.dados.forEach((m) => {
        if (m.isSelected) {
          m.isSelected = false;
          valEnt.push({
            _rNR_MEDICO: m.ce401_nr_medico,
            _rNR_CRM: m.ce401_nr_crm,
            _rUF_CRM: m.ce401_sg_crmuf,
            _rNM_MEDICO: m.ce401_nm_medico,
            _rCD_OPESIS: usuario.base.us01_nr_usr,
          });
        }
      });
      try {
        await this.request({
          metodo: "get",
          sp: "SP_CE360906001",
          params: valEnt,
        });
        this.drawer = false;
        this.notification().success({
          mensagem: "Dados salvos com sucesso!",
          timeout: 3000,
        });
      } catch (err) {
        this.notification().error({erro: "Erro ao editar as informações!"});
      }
    },

    _commitEdit() {
      if (this._currentEditItem) {
        this.flex.columns.forEach((col) => {
          let input = this.flex.hostElement.querySelector("#" + col.binding);
          if (input) {
            let value = wjcCore.changeType(
              input.value,
              col.dataType,
              col.format,
            );
            if (wjcCore.getType(value) == col.dataType) {
              this._currentEditItem[col.binding] = value;
            }
          }
        });
        this.salvaEdicao();
      }
      this._currentEditItem = null;
      this.flex.invalidate();
    },
    _cancelEdit() {
      if (this._currentEditItem) {
        this._currentEditItem = null;
        this.flex.invalidate();
      }
    },

    aplicarBackup() {
      const backup = this.abas.find(
        (aba) => aba.nome_aba === this.nomeAbaSelecionada,
      ).backup;
      this.dados = backup;
      this.keyFlexGrid++;
    },
    deletarDaTabela(e) {
      this.dados = this.dados.filter((item) => !e.includes(item));
      const selectedPesquisa = this.abas.find(
        (aba) => aba.nome_aba === this.nomeAbaSelecionada,
      );
      selectedPesquisa.pesquisa = this.dados;
      const abasVazias = this.abas.filter((aba) => aba.pesquisa.length === 0);
      this.abas = this.abas.filter((aba) => !abasVazias.includes(aba));

      //this.keyFlexGrid++
    },
    moveDados(dados) {
      if (this.abas[dados.aba]) {
        this.abas[dados.aba].pesquisa = [
          ...dados.items,
          ...this.abas[dados.aba].pesquisa,
        ];
      } else {
        this.abas.push({
          nome_aba: `${this.$t("TelaLojas.pesquisa")} ${this.abas.length +
            1} - ${dayjs()
            .locale("pt-br")
            .format("l")} - ${this.$t("TelaLojas.quantidade")}: ${
            dados.items.length
          }`,
          pesquisa: dados.items,
        });
      }
    },
    /* eslint-disable */
    //dadosConfirmados(dadosCorretos, dadosIncorretos, nomeArquivo) {},

    /* eslint-disable */
  },
};
